import React from "react";

interface RoundChartProps {
    percentage: number;
    size: number;
    strokeWidth: number;
    color1: string;
    color2: string;
    value: string
}

const RoundChart: React.FC<RoundChartProps> = ({
                                                   percentage,
                                                   size,
                                                   strokeWidth,
                                                   color1,
    value,
                                                   color2,
                                               }) => {
    const radius = size / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (percentage / 100) * circumference;
    return (
        <svg
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                fill="none"
                stroke={color2}
                strokeWidth={strokeWidth}
            />

            <circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                fill="none"
                stroke={color1}
                strokeWidth={strokeWidth}
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                strokeLinecap="round"
                transform={`rotate(-90 ${size / 2} ${size / 2})`}
            />
            <text
                x="50%"
                y="50%"
                textAnchor="middle"
                alignmentBaseline="middle"
                fontSize="18"
                fill="black"
                fontWeight={700}
            >
                {value}
            </text>
        </svg>
    );
};

export default RoundChart;
