import React from "react";
import { Switch } from "react-router-dom";
import { PublicRoute } from "./PublicRoute";
import { ProtectedRoute } from "./ProtectedRoute";
import { DashboardLayout } from "components/layouts/DashboardLayout";
import Cookies from "universal-cookie";
import {Businesses} from "../pages/Businesses";
import {WaitingList} from "../pages/WaitingList";
// import Login from "../pages/LoginPage";

import {
  LoginPage,
  // Businesses,
  CategoryPage,
  Subscription,
  UsersProfile,
  SubCategory,
  Analytics,
  Island,
  Notification,
} from "pages";
const cookies = new Cookies();

export const Routes: React.FC = () => {
  let isLoggedIn = false;
  const token = cookies.get("TOKEN");
  console.log('token', token); 
  const userIdString = sessionStorage.getItem("userId");

  const userId = userIdString ?  JSON.parse(userIdString) : null;
  const userType = localStorage.getItem("userType");
  // const [showChangePassword, setShowChangePassword] = useState(false);
  
  console.log('userId-- in session', userId, 'userType-- in localstorage',userType, 'token-- in cookies', token);
  if(token){
    // console.log('token not found in cookie', token);
    // return <Login />;
   isLoggedIn = true;
  }
  // console.log('token exist', token);
  return (
    <Switch>
      <PublicRoute
        path="/login"
        authorized={isLoggedIn}
        component={LoginPage}
      />
      <DashboardLayout>
        <ProtectedRoute
          path="/"
          authorized={isLoggedIn}
          component={Analytics}
        />
        <ProtectedRoute 
          path="/businesses"
          authorized={isLoggedIn}
          component={Businesses}
        />
        <ProtectedRoute 
          path="/waitinglist"
          authorized={isLoggedIn}
          component={WaitingList}
        />
        <ProtectedRoute
          path="/category"
          authorized={isLoggedIn}
          component={CategoryPage}
        />
        <ProtectedRoute
          path="/users"
          authorized={isLoggedIn}
          component={UsersProfile}
        />
        <ProtectedRoute
          path="/island"
          authorized={isLoggedIn}
          component={Island}
        />
        <ProtectedRoute
          path="/sub-category"
          authorized={isLoggedIn}
          component={SubCategory}
        />
        <ProtectedRoute
          path="/subscriptions"
          authorized={isLoggedIn}
          component={Subscription}
        />
        <ProtectedRoute
          path="/notifications"
          authorized={isLoggedIn}
          component={Notification}
        />
      </DashboardLayout>
    </Switch>
  );
};
