import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Table } from "common/Table/Table";
import { Modal } from "common/Modal";


const columns = [
    {key: "name", title: "Name", dbColName: "name"},
    {key: 'businessName', title: "Business Name", dbColName: "businessName"},
    {key: 'email', title: "Email", dbColName: "email"},
    {key: "joiningDate", title: "Joining Date", dbColName: "joiningDate"},

]



export const WaitingList = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]); //all data in waiting list
    // const [waitingList, setWaitingList] = useState<any>({});
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchWaitingList = async () => {
            const baseUrl = process.env.REACT_APP_BASE_URL;
            const configuration = {
                method: 'get',
                url: `${baseUrl}/waitinglist/get-waiting-list`,
                
            };
            try{
                const result = await axios(configuration);
                if(result.status === 200){
                    setData(result.data.data);
                }
                setLoading(false);
            }catch(err){
                console.log('error in fetching waiting list', err);
            }
        }
        fetchWaitingList();
    }, []);
    console.log('waiting list', data);

    // const getWaitingList = async (_id) => {
    //     const configuration = {
    //         method: 'get',
    //         url: `${process.env.REACT_APP_BASE_URL}/waitinglist/get-waiting-list`
    //     };
    //     try{
    //         const result = await axios(configuration);
    //         if(result.status === 200){
    //             setWaitingList(result.data.data);
    //             console.log('waiting list', waitingList);
    //         }
    //     }catch(err){
    //         console.log('error in fetching waiting list data', err);
    //     }
    //     setShowModal(true);
    // }
    
    
    return(
        <Fragment>
            <h1 className="text-zinc-800 text-x1 font-bold">Waiting List</h1>
            <Table columns={columns} data={data} totalItems={data.length} loadingData={loading} />
            {/* <a href="#" onClick={getSpecificWaitingList}>get Waiting list</a> */}
            <Modal  
                showModal={showModal}
                setShowModal={setShowModal}
                width="w-1/2"
                title="Business Details"

             >
                <ul>
                    {/* <li><strong>Name: </strong>{waitingList.name}</li>
                    <li><strong>Business Name: </strong>{waitingList.address}</li>
                    <li><strong>Email: </strong>{waitingList?.userId?.email}</li>
                    <li><strong>Joining Date</strong>{waitingList?.joiningDate}</li> */}
                    
                </ul>
            </Modal>
        </Fragment>
    );
}