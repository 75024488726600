import { InputField } from 'common/form/InputField';
import React, {useState, FC} from 'react';
import axios from 'axios';
import classNames from 'classnames';
import {Modal} from 'common/Modal'; 

export const Notification: FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [notificationData, setNotificationData] = useState({title: '', message: ''});
    // const [formData, setFormData] = useState<any>({title: '', messge: ''});
    
    const sendNotification = (e) => {
      e.preventDefault();
      console.log('sending notification data: ', notificationData);
      const configuration = {
        method: 'post',
        url: process.env.REACT_APP_BASE_URL+`/notification/send-notification-to-all-users`,
        data: notificationData,
      };
      axios(configuration)
      .then((response) => {
        if(response.status === 200){
          console.log('notifications sent', response);
          setShowModal(true);
        }else{
          console.log('notification sending failed', response);
        }
      })
      .catch((err) => {
        console.log('error in sending notification', err);
        alert(err.response.data.message);
      })
    }
  
    const dataChange = async (e) => {
      const {name, value} = e.target;
      setNotificationData((prevData) => ({... prevData, [name]:value}));
    };

    return(
        <div className="flex justify-center h-screen">
            <form className="p-6 flex flex-col w-full max-w-md" onSubmit={sendNotification}>
                <h2 className="text-2xl font-bold mb-4">Send Message</h2>
                <InputField
                    placeholder="Enter title"
                    label="Title"
                    layout="mt-2"
                    value={notificationData.title}
                    type="text"
                    required
                    name="title" onChange={dataChange}
                />
                <label htmlFor="message" className={styles.label} style={{marginTop:'20px'}}>
                  Message <span className="text-red-500">*</span>
                </label>
                <textarea
                    placeholder="Enter message" 
                    className={`${styles.input} ${styles.focused}`}
                    value={notificationData.message} rows={3}
                    required
                    name="message" onChange={dataChange}
                />
                {/* <p className={styles.errorMessage}>{errorMessage}</p> */}
                <button type="submit" className={styles.button}>
                    Send
                </button>
            </form>
            <Modal showModal={showModal} setShowModal={setShowModal} width='w-1/3' title='Notifications'>
              <h4>Notifications sent to Users Successfully</h4>
              <button type='button' className={styles.okButton} onClick={()=>setShowModal(false)}>Ok</button>
            </Modal>
        </div>
    );
}

const styles = {
    input:
      "mt-1 bg-white placeholder:text-sm rounded-md text-gray-800 appearance-none block w-full focus:ring-0 p-4 border border-gray-300 placeholder-gray-400 focus:outline-none sm:text-sm ",
    button:
      " mb-2 mt-5 w-full disabled:bg-red-400 bg-primary disabled:cursor-not-allowed duration-500 py-3 hover:bg-yellow-500 rounded-md font-semibold text-white text-center",
    okButton: "mb-2 mt-5 mr-20 ml-20 w-24 disabled:bg-red-400 bg-primary disabled:cursor-not-allowed duration-500 py-3 hover:bg-yellow-500 rounded-md font-semibold text-white text-center",
    label: "block text-sm font-semibold tracking-wide",
    error: "border-red-500  focus:ring-0 focus:border-2 focus:border-red-600 placeholder:text-red-400 placehoder:font-xs ",
    focused: "ring-0 focus:border-[1px] focus:border-primary",
    errorMessage: "text-red-500 text-xs font-medium tracking-wide mt-1",
  };