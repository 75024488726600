import React from "react";
import classNames from "classnames";
import { inputStyles } from "./InputField";

type Props = {
  placeholder: string;
  label: string;
  layout?: string;
  errorMessage?: string;
  required?: boolean;
  className?: string;
  name: string;
  options: { value: string; label: string }[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void; // Add onChange prop
  value?: string; //add value prop to type definition
};

export const Select: React.FC<Props> = ({
  label,
  placeholder,
  className,
  required,
  name,
  errorMessage,
  layout,
  onChange, // Include onChange prop
  options,
    ...rest
}) => {
  return (
    <div className={layout}>
      <label
        htmlFor={label}
        className="block text-sm font-semibold tracking-wide text-gray-700"
      >
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <select
        name={name}
        onChange={onChange}
        {...rest}
        placeholder={placeholder}
        className={classNames(inputStyles.input + className, {
          [inputStyles.error]: !!errorMessage,
          [inputStyles.focused]: !errorMessage,
        })}
      >
        <option value="">
          {placeholder}
        </option>
        {options.map((item) => (
          <option value={item.value}>{item.label}</option>
        ))}
      </select>
      <p className={inputStyles.errorMessage}>{errorMessage}</p>
    </div>
  );
};
