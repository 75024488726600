import React, { FC } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

type Props = {
  active: boolean;
  icon?: React.ReactNode;
  label: string;
  to: string;
  className?: string;
};

export const NavLink: FC<Props> = ({ active, to, icon, label, className }) => {
  return (
    <div className={cn("flex items-center group")}>
      <div
        className={cn({
          "w-1 h-8  bg-primary rounded-md": active,
        })}
      ></div>
      <Link
        to={to}
        className={cn("flex py-1 font-medium px-8 items-center w-full " + className)}
      >
        <div
          className={cn(
            "flex items-center gap-x-6 w-full rounded-lg p-3",
            {
              "bg-primary text-gray-800": active,
              "hover:bg-primary hover:text-gray-800 text-zinc-400": !active,
            }
          )}
        >
          {icon}
          {label}
        </div>
      </Link>
    </div>
  );
};
