import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Table } from "common/Table/Table";
import { Modal } from "common/Modal";


const columns = [
    {key: "name", title: "Name", dbColName: "name"},
    {key: 'address', title: "Address", dbColName: "address"},
    {key: 'aboutBusiness', title: "About", dbColName: "aboutBusiness"},
    {key: "userId", title: "User", dbColname: "userId.fullName"},
    {key: "islandId.island", title: "Island", dbColName: "islandId.island"},
    {key: 'rating', title: "Rating", dbColName: "averageRating"},
    {key: 'contact', title: "Contact", dbColName: "contact"}, 

]



export const Businesses = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]); //all businesses
    const [specificBusiness, setSpecificBusiness] = useState<any>({});
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchBusinesses = async () => {
            const baseUrl = process.env.REACT_APP_BASE_URL;
            const configuration = {
                method: 'get',
                url: `${baseUrl}/business/get-all-businesses`,
                
            };
            try{
                const result = await axios(configuration);
                if(result.status === 200){
                    setData(result.data.data);
                }
                setLoading(false);
            }catch(err){
                console.log('error in fetching businesses', err);
            }
        }
        fetchBusinesses();
    }, []);
    console.log('businesses', data);

    const getSpecificBusinessData = async (_id) => {
        const configuration = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/business/get-specific-business/65360e419207ee5cf79b8bc9`
        };
        try{
            const result = await axios(configuration);
            if(result.status === 200){
                setSpecificBusiness(result.data.data);
                console.log('specific-business-data', specificBusiness);
            }
        }catch(err){
            console.log('error in fetching business data', err);
        }
        setShowModal(true);
    }
    
    
    return(
        <Fragment>
            <h1 className="text-zinc-800 text-x1 font-bold">Business</h1>
            <Table columns={columns} data={data} totalItems={data.length} loadingData={loading} />
            <a href="#" onClick={getSpecificBusinessData}>get specific business data</a>
            <Modal  
                showModal={showModal}
                setShowModal={setShowModal}
                width="w-1/2"
                title="Business Details"

             >
                <ul>
                    <li><strong>Name: </strong>{specificBusiness.name}</li>
                    <li><strong>Address: </strong>{specificBusiness.address}</li>
                    <li><strong>Contact: </strong>{specificBusiness.contact}</li>
                    <li><strong>About: </strong>{specificBusiness.aboutBusiness}</li>
                    <li><strong>Island: </strong>{specificBusiness?.islandId?.island}</li>
                    <li>Social</li>
                    <li><strong>facebook: </strong>{specificBusiness?.social?.facebook}</li>
                    <li><strong>Twitter: </strong>{specificBusiness?.social?.twitter}</li>
                    <li><strong>Instagram: </strong>{specificBusiness?.social?.instagram}</li>
                    <li><strong>Whatsapp: </strong>{specificBusiness?.social?.whatsapp}</li>
                    <li><strong>Website: </strong>{specificBusiness?.social?.website}</li>
                    <li><strong>Category: </strong>{specificBusiness?.categoryId?.category}</li>
                    <li><strong>User: </strong>{specificBusiness?.userId?.fullName}</li>
                    <li><strong>Email: </strong>{specificBusiness?.userId?.email}</li>
                    
                </ul>
            </Modal>
        </Fragment>
    );
}