import {Table} from "common/Table/Table";
import {DefaultActions} from "common/Table/defaultAction";
import React, {useState, useEffect, useRef} from "react";
import axios from 'axios';
import {Modal} from "common/Modal";
import {InputField} from "common/form/InputField";
import {Fragment} from "react";
import {DeleteModal} from "../common/DeleteModal";

export const Island = () => {
    const [data, setData] = useState<any>([]); //used in get request to set the returned data
    const [formData, setFormData] = useState({island: ''}); //used in post/put request to set data
    const [isEditMode, setIsEditMode] = useState(false); //will update/set to true in editCategory
    const [editItemId, setEditItemId] = useState(null); //track the item being edited. will set in editCategory
    const [showIslandForm, setShowIslandForm] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const baseUrl = process.env.REACT_APP_BASE_URL;
            const configuration = {
                method: 'get',
                url: `${baseUrl}/island/get-all-islands`,
            }
            try {
                const result = await axios(configuration);
                if (result.status === 200) {
                    setData(result.data.data);
                }
            } catch (err) {
                console.log('error in fetching data', err);
            }
        }
        fetchData();
    }, []);

    const columns = [
        {key: "name", title: "Island Name", dbColName: "island"},
        {
            key: "action",
            title: "Actions",
            render: (row: any) => (
                <DefaultActions
                    id={row._id}
                    handleDelete={() => openDeleteDialog(row._id)}
                    onEdit={() => editIsland(row)}
                />
            ),
        },
    ];

    const openDeleteDialog = (id) => {
        setIsDeleteDialogOpen(true);
        setItemToDelete(id);
    }

    const editIsland = (island) => {
        if (island) {
            setIsEditMode(true);
            setEditItemId(island._id);
            setShowIslandForm(true);
            setFormData({island: island?.island});
        }
    };

    const deleteIsland = (id) => {
        const baseUrl = process.env.REACT_APP_BASE_URL;

        if (itemToDelete) {
            const configuration = {
                url: `${baseUrl}/island/delete-island/${itemToDelete}`,
                method: 'delete',
            }
            axios(configuration)
                .then((result) => {
                    if (result.status === 200) {

                        window.location.reload();
                    }
                })
                .catch((err) => {
                    alert('error in deleting island');
                    console.log('error in deleting island', err);
                })
        }

    };


    const handleSubmit = (e) => {
        e.preventDefault();

        const baseUrl = process.env.REACT_APP_BASE_URL;
        if (isEditMode) { //if edit mode on/true and edit id available that means editing/updating
            const configuration = {
                method: 'put',
                url: `${baseUrl}/island/update-island/${editItemId}`,
                data: formData,
            }
            axios(configuration)
                .then((result) => {
                    if (result.status === 200) {
                        setIsEditMode(false);
                        setEditItemId(null);
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    console.log('error in updating Island', err);
                    // alert(`error in updating island`, err);
                })
        } else { //adding category / create/submit category
            const configurations = {
                method: 'post',
                url: `${baseUrl}/island/add-island`,
                headers: {
                    'Content-Type': 'application/json', // Make sure this header is set
                },
                data: formData,
            }
            axios(configurations)
                .then((result) => {
                    if (result.status === 200) {
                        setFormData({island: ''});

                        window.location.reload();
                    }
                })
                .catch((err) => {
                    // alert('Error in adding Island. see the console for details', err);
                    console.log('error in adding island', err);
                });
        }

    };
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({...prevData, [name]: value,}));
    };

    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="text-zinc-800 text-xl font-semibold">Island</h1>
                <div className="w-40">
                    <button
                        type="button"
                        onClick={() => {
                            setShowIslandForm(true)
                            setFormData({ island: "" })
                        }}
                        className={styles.button}
                    >
                        Add Island
                    </button>
                </div>
            </div>
            <Table columns={columns} data={data} totalItems={data.length}/>
            <Modal  //add / update cateogory
                showModal={showIslandForm}
                setShowModal={setShowIslandForm}
                setIsEditing={setIsEditMode}
                width="w-full md:w-1/3"
                title={isEditMode ? "Update Island" : "Add Island"} // ok/delete button should call to delete with id which set itemToDelete
            >
                <form className="p-6 flex flex-col" onSubmit={handleSubmit}>
                    <InputField
                        placeholder="Enter island"
                        label="Island"
                        layout="mb-4"
                        type="text"
                        value={formData.island}
                        required
                        name="island" onChange={handleChange}
                    />
                    <button type="submit" className={styles.button}>
                        {isEditMode ? "Update" : "Add"}
                    </button>
                </form>
            </Modal>
            <DeleteModal
                showModal={isDeleteDialogOpen}
                handleClickDelete={deleteIsland}
                setShowModal={setIsDeleteDialogOpen}
                description="Are you sure you want to delete the island?"
                title=""
            />
        </div>
    )
};

const styles = {
    input:
        "mt-1 bg-white placeholder:text-sm rounded-md text-gray-800 appearance-none block w-full focus:ring-0 p-4 border border-gray-300 placeholder-gray-400 focus:outline-none sm:text-sm ",
    button:
        " w-full disabled:bg-red-400 bg-primary disabled:cursor-not-allowed duration-500 px-4 py-3 hover:bg-yellow-500 rounded-md font-semibold text-white text-center",
    label: "block text-sm font-semibold tracking-wide",
};
