import React, {useState, useEffect, Fragment} from 'react';
import axios from 'axios';
import RoundChart from "../common/RoundChart";
import {LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer} from 'recharts';


export const Analytics = () => {
    const [tilesData, setTilesData] = useState<any>({});
    const [lineChartData, setLineChartData] = useState<any>([]);
    const [roundChartData, setRoundChartData] = useState<any>({});
    const [recentBusinessData, setRecentBusinessData] = useState<any>([]);

    useEffect(() => {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const tilesReport = async () => {
            const configuration = {
                method: 'GET',
                url: `${baseUrl}/dashboard/get-tiles-data`,
                headers: {
                    'Content-Type': 'Application/json',
                },
            }
            const response = await axios(configuration);
            if (response.status === 200) {
                setTilesData(response.data.data);
            }
        }
        const lineChartReport = async () => {
            const configuration = {
                method: 'GET',
                url: `${baseUrl}/dashboard/get-graph-data`,
                headers: {
                    'Content-Type': 'Application/json',
                },
            }
            const response = await axios(configuration);
            if (response.status === 200) {
                setLineChartData(response.data.data.map(item => ({
                    "Users Joined": item.users,
                    "Business Joined": item.businesses,
                    month: item.month
                })));
            }
        }
        const roundChartReport = async () => {
            const configuration = {
                method: 'Get',
                url: `${baseUrl}/dashboard/get-round-chart-data`,
                headers: {
                    'Content-Type': 'Application/json',
                },
            }
            const response = await axios(configuration);
            if (response.status === 200) {
                setRoundChartData(response.data.data);
            }
        }

        const recentBusinessReport = async () => {
            const configuration = {
                method: 'Get',
                url: `${baseUrl}/dashboard/recent-business-subscription`,
                headers: {
                    'Content-Type': 'Application/json',
                },
            }
            const response = await axios(configuration);
            if (response.status === 200) {
                setRecentBusinessData(response.data.data);
            }
        }

        const fetchData = async () => {
            await lineChartReport();
            await tilesReport();
            await roundChartReport();
            await recentBusinessReport();
        }
        fetchData();
    }, []);

    return (
        <Fragment>
            <h1 className="text-zinc-950 text-2xl font-bold">Analytics Overview</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-4">
                <div className="flex-1  text-center p-6 bg-opacity-10 bg-orange-400 rounded-xl">
                    <div className={styles.heading}>{tilesData.registeredUsers}</div>
                    <div className={styles.subHeading}>Number of Users</div>
                    <div className={styles.text}>{tilesData.usersPercentage}</div>
                </div>
                <div className="flex-1  text-center p-6 bg-opacity-20 bg-stone-300 rounded-xl">
                    <div className={styles.heading}>{tilesData.registeredBusinesses}</div>
                    <div className={styles.subHeading}>Number of Businesses</div>
                    <div className={styles.text}>{tilesData.businessesPercentage}</div>
                </div>
                <div className="flex-1  text-center p-6 bg-opacity-10 bg-gray-600 rounded-xl">
                    <div className={styles.heading}>{tilesData.newUsersJoined}</div>
                    <div className={styles.subHeading}>New Users Joined</div>
                    <div className={styles.text}>{tilesData.newUsersJoinedPercentage}</div>
                </div>
                <div className="flex-1  text-center p-6 bg-opacity-10 bg-sky-600 rounded-xl">
                    <div className={styles.heading}>{tilesData.newBusinessesJoined}</div>
                    <div className={styles.subHeading}>New Business Joined</div>
                    <div className={styles.text}>{tilesData.newBusinessesJoinedPercentage}</div>
                </div>
            </div>
            <div className="mt-4 grid grid-cols sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div
                    className="md:col-span-2 overflow-auto overscroll-contain w-full bg-white rounded-[10px] border border-neutral-200 pb-6 pt-4 pr-6">
                    <div className="flex items-center justify-between">
                        <p className="flex items-start justify-start text-xl font-semibold ml-6 mt-2 text-gray-700">Joining</p>
                        <select className="rounded-md px-3 text-sm py-2 border border-gray-300">
                            <option>Month</option>
                        </select>
                    </div>
                    <ResponsiveContainer height={300} width="100%">
                        <LineChart
                            data={lineChartData}
                            margin={{
                                top: 10,
                                right: 30,
                            }}
                        >
                            <Line type="monotone" dataKey="Users Joined" stroke="#7071E8"/>
                            <Line type="monotone" dataKey="Business Joined" stroke="#FF6C22"/>
                            <XAxis dataKey="month"/>
                            <YAxis minTickGap={20} className="text-gray-100 bg-red-200"/>
                            <Tooltip contentStyle={{borderRadius: 10, color: "white", background: "#F8D20F"}}
                                     labelStyle={{color: "white"}} wrapperStyle={{border: "none"}}
                            />
                            <Legend iconSize={10} wrapperStyle={{ top: -25, fontSize: 14 }} iconType="circle" verticalAlign="top"/>
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div className="bg-white rounded-[10px] border border-neutral-200 p-4 flex flex-col gap-4">
                    <div className="border-b border-gray-200 px-3 pb-4 flex flex-col gap-2 items-start">
                        <p className="text-gray-700 text-xl font-medium text-left">Subscription earning</p>
                        <p className="text-3xl font-semibold text-gray-700"><span
                            className=" text-gray-400">$&nbsp;</span>{roundChartData.earnings}</p>
                    </div>
                    <div className="flex items-center justify-center h-full gap-4">
                        <div className="flex flex-col items-center gap-4">
                            <RoundChart
                                color1="#7071E8"
                                color2="#F2F4F7"
                                percentage={roundChartData.percentageSubscribed}
                                value={roundChartData.subscribedBusinesses}
                                size={120}
                                strokeWidth={12}
                            />
                            <p className="w-32 text-center text-gray-600 font-semibold text-sm">No. of Business
                                Subscribed</p>
                        </div>
                        <div className="flex flex-col items-center gap-4">
                            <RoundChart
                                color1="#FF6C22"
                                color2="#F2F4F7"
                                percentage={roundChartData.percentagePendingSubscription}
                                value={roundChartData.subscriptionPendingBusinesses}
                                size={120}
                                strokeWidth={12}
                            />
                            <p className="w-40 text-center text-gray-600 font-semibold text-sm">No. of Business Pending
                                Subscription</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="min-w-full py-6">
                <p className="flex items-start justify-start text-xl font-semibold text-gray-700 ">Recent business
                    subscription</p>
                <table className="min-w-full mt-3 divide-y">
                    <tbody className="divide-y text-sm text-gray-700">
                    {recentBusinessData.map((business) => (
                        <tr className="p-2">
                        <td><input type="checkbox" className="checked:text-primary drop-shadow-sm" /></td>
                        <td className="py-3">{business.invoice_url}</td>
                        <td className="py-3">{business.date}</td>
                        <td className="py-3">{business.time}</td>
                        <td className="py-3">{business.subscriber}</td>
                        <td className="py-3">{business.amount}</td>
                    </tr>
                    ))}
                        
                    {/* <tr className="p-2">
                        <td><input type="checkbox" className="bg-primary" /></td>
                        <td className="py-3">CAAAAA</td>
                        <td className="py-3">21 November, 2023</td>
                        <td className="py-3">09:30 PM</td>
                        <td className="py-3">Gold Smith</td>
                        <td className="py-3"></td>
                    </tr>
                    <tr className="p-2">
                        <td><input type="checkbox" className="bg-primary" /></td>
                        <td className="py-3">CAAAAA</td>
                        <td className="py-3">21 November, 2023</td>
                        <td className="py-3">09:30 PM</td>
                        <td className="py-3">Gold Smith</td>
                        <td className="py-3"></td>
                    </tr> */}
                    </tbody>
                </table>
            </div>
        </Fragment>
    );

};

const styles = {
    heading: "text-zinc-800 text-[34px] font-semibold",
    subHeading: "mt-1 text-zinc-950 text-[10.83px] font-bold",
    text: "mt-1 text-neutral-400 text-xs",
};
