import {Table} from "common/Table/Table";
import {DefaultActions} from "common/Table/defaultAction";
import React, {Fragment, useState, useEffect} from "react";
import {Modal} from "../common/Modal";
import {InputField} from "common/form/InputField";
import {DeleteModal} from "../common/DeleteModal";

export const Subscription = () => {
    const [data, setData] = useState<any>([]);
    const [showSubPlan, setShowSubPlan] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editItemId, setEditItemId] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);

    const [formData, setFormData] = useState<any>({duration: '', amount: '', discount: ''});
    const [isDeleteDialog, setIsDeleteDialog] = useState(false); //for showing delete dialog

    useEffect(() => {
        const fetchSubscriptio = async () => {
            const baseUrl = process.env.REACT_APP_BASE_URL;
            const url = baseUrl + '/subscriptionplan/get-all-subscription-plans';
            fetch(url)
                .then((response) => {
                    if (response.status === 200) {
                        console.log('subscriptions', response);
                        return response.json();
                    }
                })
                .then((data) => {
                    setData(data.data);
                })
                .catch((err) => console.log(`error in getting subscription ${err}`));
        }
        fetchSubscriptio();
    }, []);

    const columns = [
        {key: "duration", title: "Duration", dbColName: "duration"},
        {key: "amount", title: "Amount", dbColName: "amount"},
        {key: "discount", title: "Discount", dbColName: "discount"},
        {
            key: "action",
            title: "Actions",
            render: (row: any) => (
                <DefaultActions
                    id={row._id}
                    onEdit={() => editSubscription(row._id)}
                    handleDelete={() => openDeleteDialog(row._id)}
                />
            ),
        },
    ];
    const editSubscription = async (id: any) => {
        //1- find the doc/record in data (returned from get request)
        const subsToEdit = data.find((item) => (item._id === id));
        //2- if the doc exist to delete
        if (subsToEdit) {
            //3- set edit mode on and save editing id in useState. also setting formData
            setIsEditMode(true); //editing mode on by clicking edit icon to execute put request on submit
            setEditItemId(id); //saving editing id in useState to use it in request uri
            setShowSubPlan(true);
            //setting formData fields found/retrieved from data(subsToEdit). the fields are sets in onChange & here in edit
            setFormData({duration: subsToEdit.duration, amount: subsToEdit.amount, discount: subsToEdit.discount});
        }
    };
    const openDeleteDialog = async (id) => {
        setIsDeleteDialog(true);
        setItemToDelete(id);
    }
    const addSubscriptionLink = () => {
        setShowSubPlan(true);
        setFormData({})
    }
    const addSubscription = async (e) => {
        e.preventDefault();
        const baseUrl = process.env.REACT_APP_BASE_URL;
        if (isEditMode && editItemId) { //update /edit
            const endPoint = `${baseUrl}/subscriptionplan/update-subscriptionplan/${editItemId}`;
            const requestOptions = {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    //others headers i.e auth token
                },
                body: JSON.stringify(formData),
            };
            //making put request
            fetch(endPoint, requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        // throw new Error('Network response was not ok');
                        return response.json().then((jsonData) => {
                            throw new Error(jsonData.message);
                        });
                    }
                    return response.json();
                })
                .then((data) => {
                    // alert(data.message);
                    console.log('subscription updated', data);
                    window.location.reload();
                })
                .catch((err) => {
                    alert(err.message);
                    console.log('error in updating subscription', err);
                });
        } else { //insert / add /create
            const endPoint = `${baseUrl}/subscriptionplan/create-subscriptionplan`
            const requestOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            };
            fetch(endPoint, requestOptions)
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return response.json().then((jsonData) => {
                            throw new Error(jsonData.message);
                        });
                    }
                })
                .then((data) => {
                    console.log(`subscription added ${data}`);
                    setShowSubPlan(false);
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(`error in adding subscription ${err}`);
                })
        }
    }
    const handleChange = async (e) => {
        const {name, value} = e.target;
        console.log(e.target.value);
        setFormData((prevData) => ({...prevData, [name]: value}));
    }
    const deleteSubscriptin = async (id) => {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        //make the delete dialog visible
        if (itemToDelete) {
            const apiEndPoint = `${baseUrl}/subscriptionplan/delete-subscriptionplan/${itemToDelete}`;
            const requestOptions = {
                method: 'delete',
                headers: {
                    'Content-Type': 'application/json',
                },

            };
            fetch(apiEndPoint, requestOptions)
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return response.json().then((jsonData) => {
                            throw new Error(jsonData.message);
                        })
                    }
                })
                .then((data) => {
                    console.log('deleted data', data);
                    // alert(data.message);
                    window.location.reload();
                })
                .catch((err) => {
                    alert(err.message);
                    console.log('error in deleting subscription', err);
                });
        }

    };
    const closeDeleteDialog = () => { //cancel button in delete dialog
        setIsDeleteDialog(false);
        setItemToDelete(null);
    }
    return (
        <Fragment>
            <div className="flex justify-between items-center">
                <h1 className="text-zinc-800 text-xl font-semibold">Subscription Plan</h1>
                <div className="w-48">
                    <button
                        type="button"
                        onClick={(e) => addSubscriptionLink()}
                        className={styles.button}
                    >
                        Add Subscription Plan
                    </button>
                </div>
            </div>
            <Table columns={columns} data={data} totalItems={data.length}/>
            <Modal showModal={showSubPlan} setShowModal={setShowSubPlan} width="w-full md:w-1/3" setIsEditing={setIsEditMode}
                   title={isEditMode ? "Update Subscription" : "Add Subscription"}>
                <form className="p-6 flex flex-col" onSubmit={addSubscription}>
                    <InputField
                        placeholder="Enter duration"
                        label="Duration"
                        value={formData.duration}
                        layout="mt-2"
                        type="text"
                        required
                        name="duration" onChange={handleChange}
                    />
                    <InputField
                        placeholder="Enter amount"
                        label="Amount"
                        value={formData.amount}
                        layout="mt-2"
                        type="text"
                        required
                        name="amount" onChange={handleChange}
                    />
                    <InputField
                        placeholder="Enter discount"
                        label="Discount"
                        value={formData.discount}
                        layout="mt-2 mb-4"
                        type="text"
                        required
                        name="discount" onChange={handleChange}
                    />
                    <button type="submit" className={styles.button}>
                        {isEditMode ? "Update" : "Add"}
                    </button>
                </form>
            </Modal>

            <DeleteModal showModal={isDeleteDialog} setShowModal={setIsDeleteDialog} title={""}
                         description={"Are you sure you want to delete the subscription?"}
                         handleClickDelete={deleteSubscriptin}/>

        </Fragment>
    );
};
const styles = {
    input:
        "mt-1 bg-white placeholder:text-sm rounded-md text-gray-800 appearance-none block w-full focus:ring-0 p-4 border border-gray-300 placeholder-gray-400 focus:outline-none sm:text-sm ",
    button:
        "  w-full disabled:bg-red-400 bg-primary disabled:cursor-not-allowed duration-500 py-3 hover:bg-yellow-500 rounded-md font-semibold text-white text-center",
    label: "block text-sm font-semibold tracking-wide",

    deleteButton: "mb-2 mt-5 mr-20 w-24 disabled:bg-red-400 bg-primary disabled:cursor-not-allowed duration-500 py-3 hover:bg-red-500 rounded-md font-semibold text-white text-center",
    cancelButton: "mb-2 mt-5 w-24 disabled:bg-red-400 bg-primary disabled:cursor-not-allowed duration-500 py-3 hover:bg-yellow-500 rounded-md font-semibold text-white text-center"
};
