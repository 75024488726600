import {Modal} from "common/Modal";
import {Table} from "common/Table/Table";
import {DefaultActions} from "common/Table/defaultAction";
import React, {Fragment, useState, useEffect} from "react";
import axios from "axios";
import {InputField} from "common/form/InputField";
import {Select} from "common/form/Select";
import {DeleteModal} from "../common/DeleteModal";

export const SubCategory = () => {
    const [data, setData] = useState<any>([]); //used for returned data / get request / table/display data
    const [selectedOption, setSelectedOption] = useState("");
    const [categories, setCategories] = useState<any>([]);
    const [subCategories, setSubCategories] = useState<any>([]);
    const [formData, setFormData] = useState<any>({
        categoryId: "",
        subCategoryId: "",
        subCategory: "",
    }); //use for post/put request /insert/update, create sub category
    const [isEditMode, setIsEditMode] = useState(false); //switching to edit mode
    const [editItemId, setEditItemId] = useState(null); //it hold category id
    const [showSubCatForm, setShowSubCatForm] = useState(false); //to show & hide modal
    const [isDeleteDialog, setIsDeleteDialog] = useState(false); //showing delete dialog
    const [deleteData, setDeleteData] = useState({
        category: "",
        subCategory: "",
    }); //setting ids for delete

    // const [showModal, setShowModal] = useState(false);
    const columns = [
        {
            key: "subCategory",
            title: "Sub Category",
            dbColName: "subCategory",
            render: (item: any) => {
                return (
                    <span>{item.subCategory}</span>
                );
            },
        },
        {key: "category", title: "Category", dbColName: "category"},

        {
            key: "actions",
            title: "Actions",
            render: (row: any) => (
                <DefaultActions
                    id={row._id}
                    onEdit={() => editSubCategory(row._id, row.subCategory)}
                    handleDelete={() => openDeleteDialog(row._id, row.subCategory._id)}
                />
            ),
        },
    ];
    const editSubCategory = (catId, subCatId) => {

        if (subCatId) {
            setIsEditMode(true);
            setEditItemId(catId);
            setSelectedOption(catId);
            setShowSubCatForm(true); //make it true to visible the modal
            setFormData({subCategory: subCatId.subCategory, subCategoryId: subCatId._id, categoryId: catId});
            setSelectedOption(catId)
        }
    };


    const openDeleteDialog = (catId, subCatId) => {
        setIsDeleteDialog(true); //open dialog
        setDeleteData({category: catId, subCategory: subCatId}); //set ids for delete
    };

    const deleteSubCategory = async () => {
        //get ids (cat, subCat) from useState deleteData
        const baseUrl = process.env.REACT_APP_BASE_URL;
        try {
            const configuration = {
                method: "delete",
                url: `${baseUrl}/subcategory/delete-subcategory/${deleteData.category}/${deleteData.subCategory}`,
            };
            const response = await axios(configuration);
            if (response.status === 200) {
                window.location.reload();
            }
        } catch (err) {
            // alert(err.message);
            console.log("Error in deleting sub category", err);
        }
    };

    useEffect(() => {

        //for populating category select
        const fetchCategories = async () => {
            const baseUrl = process.env.REACT_APP_BASE_URL;
            const configuration = {
                method: "get",
                url: `${baseUrl}/category/get-all-categories`,
            };
            try {
                const response = await axios(configuration);
                if (response.status === 200) {
                    const mergedSubcategories = response.data.data.flatMap(item => //map on category/main docs i.e response.data.data
                        item.subCategory.map(subCat => ({_id: item._id, category: item.category, subCategory: subCat})) //map on subCategory
                    );
                    setSubCategories(mergedSubcategories)
                    setCategories(response.data.data); //used in select element
                    // setData(response.data.data);
                    // console.log(response.data.data);
                }
            } catch (err) {
                alert("Catgories could not returned to populate select");
                console.log("error in fetching categories", err);
            }
        };
        fetchCategories();
        // Get the select element
        // const selectElement = document.querySelector('.select-input');
        // console.log('select-element', selectElement);
        // // Change the color of the first option when the page loads
        // if (selectElement) {
        //   const firstOption = selectElement.querySelector('option:first-child');
        //   firstOption.style.color = '#999';
        // }
    }, []);

    // const transformedData = data.flatMap((category) =>
    //   category.subCategory.map((subCategory, index) => ({
    //     key: subCategory._id,
    //     index: index + 1,
    //     subCategory: subCategory.subCategory,
    //     category: category.category,
    //     actions: null, // Placeholder for actions, as they are rendered separately
    //   }))
    // );
    const addSubCatLink = () => {
        setShowSubCatForm(true);
        setFormData({ })
        setSelectedOption("")
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const baseUrl = process.env.REACT_APP_BASE_URL;
        if (isEditMode) {
            const configuration = {
                method: "put",
                url: `${baseUrl}/subcategory/update-subcategory/${editItemId}`, //editItemId is CategoryId
                data: formData, //formData has subCategory & subCategoryId
            };
            axios(configuration)
                .then((response) => {
                    if (response.status === 200) {
                        // alert(response.data.message);
                        setIsEditMode(false); // make the edit mode off
                        setEditItemId(null); //make the sub cat id null
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    console.log("error", err);
                    alert(err.response.data.error.message);
                });
        } else { //insert / create / add sub category
            const configuration = {
                method: "post",
                url: `${baseUrl}/subcategory/create-subcategory`,
                data: formData,
            };
            axios(configuration)
                .then((response) => {
                    if (response.status === 200) {
                        setFormData({categoryId: '', subCategory: ''});
                        console.log("sub category added", response);
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    alert(err.response.data.error.message);
                    console.log("error ", err);
                });
        }
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        setFormData({...formData, categoryId: event.target.value}); //setting select value
    };

    const handleChange = async (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value}); //setting textbox input value
    };
    return (
        <Fragment>

            <div className="flex justify-between items-center">
                <h1 className="text-zinc-800 text-xl font-semibold">Sub Category</h1>
                <div className="w-44">
                    <button
                        type="button"
                        onClick={addSubCatLink}
                        className={styles.button}
                    >
                        Add Sub Category
                    </button>
                </div>
            </div>

            <Table columns={columns} data={subCategories} totalItems={subCategories.length}/>
            <Modal //add / update modal
                showModal={showSubCatForm}
                setShowModal={setShowSubCatForm}
                width="w-1/3"
                setIsEditing={setIsEditMode}
                title={isEditMode ? "Update Sub Category" : "Add Sub Category"}
            >
                <form className="p-6 flex flex-col" onSubmit={handleSubmit}>
                    <Select
                        placeholder="Select Category"
                        label="Category"
                        layout="mt-2"
                        value={selectedOption}
                        options={
                            categories.map((item: any) => ({
                                value: item._id,
                                label: item.category,
                            }))
                        }
                        required
                        name="category" onChange={handleSelectChange}
                    />
                    <InputField
                        type="text"
                        placeholder="Enter sub category"
                        value={formData.subCategory}
                        label="Sub Category"
                        layout="mt-4 mb-4"
                        required
                        name="subCategory" onChange={handleChange}
                    />
                    <button type="submit" className={styles.button}>
                        {isEditMode ? "Update" : "Add"}
                    </button>
                </form>
            </Modal>

            <DeleteModal
                showModal={isDeleteDialog}
                setShowModal={setIsDeleteDialog}
                title=""
                description="Are you sure you want to delete the sub category?"
                handleClickDelete={deleteSubCategory}
            />
        </Fragment>
    );
};
const styles = {
    input:
        "mt-1 bg-white placeholder:text-sm rounded-md text-gray-800 appearance-none block w-full focus:ring-0 p-4 border border-gray-300 placeholder-gray-400 focus:outline-none sm:text-sm ",
    button:
        " disabled:bg-red-400 bg-primary disabled:cursor-not-allowed duration-500  py-3 px-4 hover:bg-yellow-500 rounded-md font-semibold text-white text-center",
    label: "block text-sm font-semibold tracking-wide",
};
