import React, { FC } from "react"
import { MobileSidebar } from "./MobileSidebar"
import { Sidebar } from "./Sidebar"
import { Banner } from "common/Banner";

interface IProps {
    children: React.ReactNode
}

export const DashboardLayout: FC<IProps> = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = React.useState(false)

    return (
        <div className="min-h-screen font-hove">
            <div className="h-screen flex overflow-hidden">
                {/* Mobile sidebar */}
                <div className="block lg:hidden">
                    <MobileSidebar
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />
                </div>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:block">
                    <Sidebar />
                </div>
                <div className="flex flex-col w-0 flex-1 overflow-hidden">
                    <Banner setSidebarOpen={setSidebarOpen}  />
                    <main className="focus:outline-none overflow-auto h-screen py-3 px-6">

                        {children}
                    </main>
                </div>
            </div>
        </div>
    )
}
