import { Modal } from "common/Modal";
import { Table } from "common/Table/Table";
import { DefaultActions } from "common/Table/defaultAction";
import { Fragment } from "react";
import React, { useState, useEffect } from "react";
import axios from "axios";

export const UsersProfile = () => {
  const [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [formData, setFormData] = useState({ userStatus: "", email: "" });
  const [editUserId, setEditUserId] = useState(null);
  const [displayForm, setDisplayForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [specificUser, setSpecificUser] = useState<any>();
  const [showModal, setShowModal] = useState(false);

  const [userStates, setUserStates] = useState({});
  const [switchState, setSwitchState] = useState(false);

  // const displayModal = () => setShowModal(true);
  // const closeModal = () => setShowModal(false);

  const handleLinkClick = async (user) => {
    setShowModal(true); //alternatively setShowModal(true);
    setFormData((prevData) => ({ ...prevData, email: user.email })); //add email to form data as api need it
    setEditUserId(user._id); //set user id for update query
    setSelectedOption(user.userStatus); //update select by clicking status/doc
    setDisplayForm(true);
  };
  const columns = [
    { key: "fullName", title: "Full Name", dbColName: "fullName" },
    { key: "email", title: "Email", dbColName: "email" },
    { key: "profile", title: "User Type", dbColName: "profileType" },

    {
      key: "userStatus",
      title: "User Status",
      render: (row: any) => (
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            defaultChecked={row.userStatus === "Active"}
            className="sr-only peer"
            onChange={(e) => updateUserStatus(row)}
          />
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
        </label>
      ),
    },
  ];
  const updateUserStatus = async (row) => {

    const setUserStatus = {
      userStatus: (row.userStatus==="Active") ? "Blocked" : "Active",
      email: row.email
    }
    // setFormData((prevData) => ({ ...prevData, email: user.email }));
    const baseURL = process.env.REACT_APP_BASE_URL;
    const configuration = {
      method: 'put',
      url: `${baseURL}/user/update-account/${row._id}`,
      headers: {
        'Content-Type': 'application/json',

      },
      data: setUserStatus,
    };
    axios(configuration)
    .then((response) =>{
      if(response.status === 200){
        window.location.reload();
      }
    })
    .catch((err) => {
      alert(err.message);
      console.log('error in updating user status', err);
    });
  }
  useEffect(() => {
    setLoading(true)
    const fetchUsers = async () => {
      const baseURL = process.env.REACT_APP_BASE_URL;
      const endpoint = baseURL + "/user/get-all-accounts";
      const requestOptions = {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      };
      fetch(endpoint, requestOptions)
        .then((response) => {
          if (!response.ok) {
            return response.json().then((jsonData) => {
              throw new Error(jsonData.message);
            });
          }
          return response.json();
        })
        .then((data) => {
          console.log("fetched users", data);
          setData(data.data);
          setLoading(false)
        })
        .catch((err) => {
          alert(err.message);
          console.log("error in fetching users", err);
        });
    };
    fetchUsers();
  }, []);

  const getSpecificUserData = async (_id) => {
    const configuration = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/user/get-specific-account/65360dd19207ee5cf79b8b22`
    };
    try{
      const result = await axios(configuration);
      if(result.status === 200){
        setSpecificUser(result.data.data);
        
      }
    }catch(err){
      console.log('error in fetching specific user', err);
    }
    setShowModal(true);
  }
  console.log('specific-user-data', specificUser);
  // const data = [
  //   {
  //     full_name: "Muhammad Ubaid",
  //     email: "ubaid@techease.com",
  //     profile: "Business",
  //   },
  // ];
  return (
    <Fragment>
      <h1 className="text-zinc-800 text-xl font-bold">Users</h1>
      <Table columns={columns} data={data} totalItems={data.length} loadingData={loading} />
      <a href="#" onClick={getSpecificUserData} >get user detail on row click</a>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        width="w-1/2"
        title="User Details"
      >
        <ul>
            <li><strong>Name: </strong>{specificUser?.fullName}</li>
            <li><strong>Email: </strong>{specificUser?.email}</li>
            <li><strong>Contact: </strong>{specificUser?.phone}</li>
            <li><strong>Bio: </strong>{specificUser?.bioData}</li>
            <li><strong>User Type: </strong>{specificUser?.profileType}</li>
            
        </ul>
      </Modal>
    </Fragment>
  );
};
