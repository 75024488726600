import {Table} from "common/Table/Table";
import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import {DefaultActions} from "common/Table/defaultAction";
import {Modal} from "common/Modal";
// import {Container, Row, Col, Form, Button,  Modal as Modal2} from 'react-bootstrap';
import {InputField} from "common/form/InputField";
import {Select} from "common/form/Select";
import {DeleteModal} from "../common/DeleteModal";

export const CategoryPage = () => {
    const [data, setData] = useState<any>([]); //used in get request to set the returned data
    const [formData, setFormData] = useState<any>({category: "", icon: null}); //used in post/put request to set data
    const [isEditMode, setIsEditMode] = useState(false); //will update/set to true in editCategory
    const [editItemId, setEditItemId] = useState(null); //track the item being edited. will set in editCategory
    const [showCatForm, setShowCatForm] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [preview, setPreview] = useState("")
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>("");

    const columns = [
        {key: "category", title: "Category", dbColName: "category"},
        {
            key: "icon",
            title: "Icon",
            dbColName: "icon",
            render: (icon: string) => <img src={icon} width="30px" height="30px"/>,
        },
        {
            key: "action",
            title: "Actions",
            render: (row: any) => (
                <DefaultActions
                    id={row._id}
                    onEdit={() => editCategory(row)}
                    handleDelete={() => openDeleteModel(row._id)}
                />
            ),
        },
    ];
    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            const configuration = {
                method: "get",
                // url: 'http://localhost:3001/category/get-all-categories',
                url: `${process.env.REACT_APP_BASE_URL}/category/get-all-categories`,
            };
            try {
                const result = await axios(configuration);
                if (result.status === 200) {
                    setData(result.data.data);
                }
                setLoading(false)
            } catch (err) {
                console.log("error in fetching data", err);
            }
        };
        fetchData();
    }, []);
    const editCategory = (category) => {
        if (category) {
            setIsEditMode(true);
            setEditItemId(category._id);
            setShowCatForm(true);
            setFormData({category: category.category, icon: category.icon});
        }
    };
    //open delete modal and set deleting id in usestate... delete icon on table clicked
    const openDeleteModel = (id) => {
        setIsDeleteModalOpen(true);
        setItemToDelete(id);
    };
    //clicked on ok/delete button on modal
    const deleteCategory = () => {
        if (itemToDelete) {
            const configuration = {
                url:
                    process.env.REACT_APP_BASE_URL +
                    "/category/delete-category/" +
                    itemToDelete,
                method: "delete",
            };
            axios(configuration)
                .then((result) => {
                    if (result.status === 200) {
                        // alert('Category deleted successfully');
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    alert("error in deleting category");
                    //visible the dialog by making state=true and setting the message/err to modal
                    console.log("error in deleting category", err);
                });
        }
    };
    const addSubCatLink = async (e) => {
        e.preventDefault();
        setFormData({category: "", icon: null})
        setPreview("")
        setShowCatForm(true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData?.category === '' || !formData?.category) {
            setError("Category is required");
        }
        if (isEditMode) {
            const configuration = {
                method: "put",
                url:
                    process.env.REACT_APP_BASE_URL +
                    "/category/update-category/" +
                    editItemId,
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            axios(configuration)
                .then((result) => {
                    if (result.status === 200) {
                        setIsEditMode(false);
                        setEditItemId(null);
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    alert(`error in updating category ${err}`);
                });
        } else {
            const configurations = {
                method: "post",
                url: process.env.REACT_APP_BASE_URL + "/category/create-category",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data", // Make sure this header is set
                },
            };
            axios(configurations)
                .then((result) => {
                    if (result.status === 200) {
                        setFormData({category: "", icon: ''});
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    alert("Error in adding Category. see the console for details");
                });
        }
    };
    const handleChange = (e) => {
        if (e.target.name === "icon") {
            const file = e.target.files[0]
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e) {
                setPreview(reader.result as string)
            }
            setFormData((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.files[0],
            }));
        } else {
            const {name, value} = e.target;
            setFormData((prevData) => ({...prevData, [name]: value}));
        }
    };

    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="text-zinc-800 text-xl font-semibold">Category</h1>
                <div className="w-40">
                    <button
                        type="button"
                        onClick={addSubCatLink}
                        className={styles.button}
                    >
                        Add Category
                    </button>
                </div>
            </div>
            <Table columns={columns} data={data} totalItems={data.length} loadingData={loading}/>
            <Modal //add / update cateogory
                showModal={showCatForm}
                setShowModal={setShowCatForm}
                setIsEditing={setIsEditMode}
                width="w-1/3"
                title={isEditMode ? "Update Category" : "Add Category"} // ok/delete button should call to delete with id which set itemToDelete
            >
                <form className="p-6 flex flex-col" onSubmit={handleSubmit}>
                    <InputField
                        placeholder="Enter category"
                        label="Category"
                        layout="mt-2"
                        onChange={handleChange}
                        type="text"
                        required
                        value={formData.category}
                        name="category"
                        errorMessage={error}
                    />
                    <InputField
                        placeholder="Select icon"
                        label="Icon"
                        layout="mt-2 mb-3"
                        onChange={handleChange}
                        type="file"
                        required
                        name="icon"
                    />
                    {preview ? <div className="mt-2 rounded-md overflow-hidden">
                        <img alt="icon" src={preview}/>
                    </div> : null}
                    <button type="submit" className={styles.button}>
                        {isEditMode ? "Update" : "Add"}
                    </button>
                </form>
            </Modal>

            <DeleteModal
                showModal={isDeleteModalOpen}
                handleClickDelete={deleteCategory}
                setShowModal={setIsDeleteModalOpen}
                description="Are you sure you want to delete the category?"
                title=""
            />
        </div>
    );
};
const styles = {
    input:
        "mt-1 bg-white placeholder:text-sm rounded-md text-gray-800 appearance-none block w-full focus:ring-0 p-4 border border-gray-300 placeholder-gray-400 focus:outline-none sm:text-sm ",
    button:
        " w-full disabled:bg-yellow-300 bg-primary disabled:cursor-not-allowed duration-500 py-3 px-4 hover:bg-yellow-500 rounded-md font-semibold text-white text-center",
    label: "block text-sm font-semibold tracking-wide",
};
