import React, {FC, Fragment} from "react"
import {Dialog, Transition} from "@headlessui/react"

type Props = {
    showModal: boolean
    setShowModal: (value: boolean) => void
    title: string
    description: string
    width?: string
    handleClickDelete: any
    padding?: string
}

export const DeleteModal: FC<Props> = ({
                                           showModal,
                                           setShowModal,
                                           handleClickDelete,
                                           title,
                                           description,
                                           width,
                                           padding,
                                       }) => {
    return (
        <Transition.Root show={showModal} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                open={showModal}
                onClose={setShowModal}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-black/20 backdrop-blur-[4px] "/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div
                            className={`${
                                width ? width : "w-full md:w-1/4"
                            } inline-block mx-auto align-bottom bg-dark-black rounded bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle`}>
                            <div className="max-w-none mx-auto">
                                <div>
                                    <div
                                        className="absolute right-7 top-5 cursor-pointer"
                                        onClick={() => {
                                            setShowModal(false)
                                        }}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 text-gray-800"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </div>
                                    <h3 className="w-full p-4 shadow-inherit font-bold leading-6 text-lg  text-gray-700 ">
                                        {""}
                                    </h3>
                                    <div
                                        className={`bg-dark-black overflow-hidden mx-auto text-center   ${
                                            padding
                                                ? padding
                                                : "sm:px-6 px-16 py-4"
                                        }`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor"
                                             className="w-20 text-red-500 mb-2 h-20 mx-auto">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"/>
                                        </svg>
                                        <h4 className="text-lg text-gray-700 font-medium">{description}</h4>
                                    </div>
                                    <div className="border-t px-8 py-4 flex items-center gap-3 justify-center">
                                        <button type="button" onClick={handleClickDelete}
                                                className="text-white bg-red-500 rounded-md px-4 py-2">Delete
                                        </button>
                                        <button type="button" onClick={() => setShowModal(false)}
                                                className="text-white bg-primary rounded-md px-4 py-2">Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
