import React, { FC, useState, useEffect } from "react";
import { InputField } from "common/form/InputField";
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const LoginPage: FC = () => {
  const [login, setLogin] = useState<any>({ email: '', password: '' });
  const [error, setError] = useState<string>("");

  //login submit
  const handleSubmit = async (e) => {
    e.preventDefault(); //prevent the form from refreshing the whole page
    // try{
    if (login.email.trim().toLocaleLowerCase() === '' || login.password.trim() === '') {
      alert("Email and Password are required!");
      return;
    }
    // const url = process.env.REACT_APP_BASE_URL +"/user/email-login";
    const configuration = {
      url: process.env.REACT_APP_BASE_URL + "/user/email-login",
      method: 'post',
      data: login
    }
    // axios(configuration).then(result => {
    //   console.log('result', result);
    // });
    console.log('configuation', configuration);
    // return;
    const response = await axios(configuration);
    console.log('login response', response);
    if (response.status !== 200) {
      setError(response.data.message);
      // return;
    }

    if (response.data.data.profileType === 'Admin') { //login successful
      alert(response.data.message);
      console.log('login response', response);
      //name of cookie, value of cookie, page/route on which cookie available
      //set the returned jwt in cookie to use it in authentication
      cookies.set("TOKEN", response.data.data.token, { path: "/" }); //set cookie in all pages
      //save the user id in session storage whenever we need it. i.e to create blog get the user id
      sessionStorage.setItem("userId", JSON.stringify(response.data.data._id));
      sessionStorage.setItem("userEmail", JSON.stringify(response.data.data.email));
      localStorage.setItem('userType', response.data.data.profileType);

      // console.log('history...', history);
      // history.push('/admin/category');
      // Redirect("/admin/category")
      // updateUserData(response.data.data); //first data is response.data obj and 2nd is api
      window.location.reload(); //create-blog route in app.js and component in blog is defined
    } else {
      alert('Invalid Login. Email, Password or Role as Admin are not matched')
    }
    // }catch(error:any){
    //     console.log('error',error);
    //     setError(error.response.data.message);
    // }
  };

  //login handle change
  const handleChange = (e) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };
  return (
    <div className="flex justify-center bg-gray-100 text-white items-center p-6 md:p-0 min-h-screen">
      <div className="w-full max-w-md rounded-md">
        <div className="px-12 mx-auto mr-10">
          <img
            alt="logo"
            src="/logo.svg"
            className="h-full w-full mx-auto object-contain"
          />
        </div>
        <form className="p-6 flex flex-col" onSubmit={handleSubmit}>
          <InputField
            placeholder="Enter your email address"
            label="Email"
            layout="mt-2"
            value={login.email}
            type="email"
            required
            name="email" onChange={handleChange}
          />
          <InputField
            type="password"
            placeholder="Enter your password"
            label="Password"
            value={login.password}
            layout="mt-4"
            required
            name="password" onChange={handleChange}
          />
          <button type="submit" className={styles.button}>
            Login
          </button>
          <p className="text-red-500">{error}</p>
        </form>
      </div>
    </div>
  );
};

const styles = {
  input:
    "mx-auto mt-1 bg-white placeholder:text-sm rounded-md text-gray-800 appearance-none block w-1/2 focus:ring-0 p-4 border border-gray-300 placeholder-gray-400 focus:outline-none sm:text-sm ",
  button:
    "mx-auto mb-2 mt-5 w-full disabled:bg-red-400 bg-primary disabled:cursor-not-allowed duration-500 py-3 hover:bg-yellow-500 rounded-md font-semibold text-white text-center",
  label: "mx-auto block text-sm font-semibold tracking-wide",
};
